import React, {useState,useEffect, useRef} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import ApiResponse from './apiresponse'; // Import the component for displaying the response
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';


function Taxreturn(){
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [isHidden, setIsHidden] = useState(true);
  const [state , setState] = useState({
    plotid: '',
})

const handleChange = (e) => { 
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const headers2 = { 
  headers: {
    'accessToken': '200',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
    // 'Access-Control-Allow-Headers': "https://api.instavoicing.com"
  }
};

const [Plotid, setPlotinfo]= useState([0]);
const [Plotid2, setPlotinfo2]= useState([0]);
const [Plotid3, setPlotinfo3]= useState([0]);
const [selectedPlot, setSelectedPlot] = useState(null);

const handleplotsearch = (e) => {
   console.log(state.plotid);
        if(!state.plotid){
            Swal.fire({
                title: "Error",
                text: "Please enter plot ID",
                // icon: "success",
                showCancelButton: true,
                closeOnCancel: true,
                cancelButtonText: "Cancel"
              }).then(result => {
              })
        }else{
            // const dashURL = "http://localhost:9900/api/getPlotDetail";
            const dashURL = "https://plotbackend.starlightsoftware.co.bw/api/getPlotDetail";
            const body = {
                'plot_no': state.plotid,
                'accessToken': 200
            }
            MySwal.fire({
                title: <p>please wait...</p>,
                didOpen: () => {
                  MySwal.showLoading()
                  axios.post(dashURL, body, headers2)
                .then((response) => {
                  console.log("response received: ", response);
                  setPlotinfo(response.data);
                  console.log(Plotid)
                  setIsHidden(false)
                  Swal.close()
                  })
                  .catch((err) => {
                  Swal.close()
                    console.log("AXIOS ERROR: ", err);
                    return MySwal.fire(err.response.data.error)
                  })
                },
              }).then(() => {
              })
        }
  } 

  const handlePlotClick = (plotNo) => {
    setSelectedPlot(plotNo);
      console.log(state.plotid);
           if(!state.plotid){
               Swal.fire({
                   title: "Error",
                   text: "Please enter plot ID",
                   // icon: "success",
                   showCancelButton: true,
                   closeOnCancel: true,
                   cancelButtonText: "Cancel"
                 }).then(result => {
                 })
           }else{
               // const dashURL = "http://localhost:9900/api/getPlotDetail";
               const dashURL = "https://plotbackend.starlightsoftware.co.bw/api/getPlotDetail";
               const body = {
                   'plot_no': plotNo,
                   'accessToken': 200
               }
               MySwal.fire({
                   title: <p>please wait...</p>,
                   didOpen: () => {
                     MySwal.showLoading()
                     axios.post(dashURL, body, headers2)
                   .then((response) => {
                     console.log("response received: ", response);
                     setPlotinfo2(response.data);
                     setPlotinfo3(response.data[0]);
                     console.log(response.data[0].coordinates.longitude)
                     setIsHidden(false)
                     Swal.close()
                     })
                     .catch((err) => {
                     Swal.close()
                       console.log("AXIOS ERROR: ", err);
                      //  return MySwal.fire(err.response.data.error)
                     })
                   },
                 }).then(() => {
                 })
           }
  };


  let latt = -34.397;
  let long = 150.644;

  if (Plotid3 && Plotid3.coordinates) {
    latt = Plotid3.coordinates.latitude;
    long = Plotid3.coordinates.longitude;
  }

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: latt, lng: long }}
      >
        <Marker position={{ lat: latt, lng: long }} /> 
      </GoogleMap>
    ))
  );
  
  const Map = () => {
    return (
      <div style={{ width: '95%', height: '800px' }}>
        <MyMapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyABTMYCruNacfh-k2pt8pCn4LgiRPUXwzg&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      </div>
    );
  };
  
      return (
              <div>
                 <div className="page-wrapper">
        <header id="page-header">
          <nav>
            <div className="left">
                 <h2>PLOTFINDER</h2> 
            </div>
            <div className="right">
              <div className="primary-nav has-mega-menu">
                <ul className="navigation">
                  <li className="active has-child"><a href="#">Home</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div id="page-content">
          <div className="hero-section full-screen has-map has-sidebar sidebar-grid">
            <div className="map-wrapper">
              <Map />
            </div>

            <div className="results-wrapper">
              <div className="sidebar-detail">
                <div className="tse-scrollable">
                  <div className="tse-content">
                    <div className="sidebar-wrapper" />
                  </div>
                </div>
              </div>
              <div className="form search-form inputs-underline">
                {/* <form> */}
                  <div className="section-title">
                    <h2>Search by plot ID</h2>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" value={state.plotid} onChange={handleChange} id="plotid" name="plotid" placeholder="Enter plot id" />
                  </div>
                  <div className="form-group">
                  <button onClick={handleplotsearch} className="btn btn-primary pull-right"><i className="fa fa-search" /></button>
                  </div>
              </div>
               <section className={isHidden? 'showme': ''} style={{marginLeft: '20px'}}>
                        <h2>Search Result</h2><br/> 
                        <p>Click on required plot below</p>
                        <ul class="tags">
                        {Plotid.map((item) => (
                            <li key={item.plot_no}><a href='#' onClick={() => handlePlotClick(item.plot_no)}>Plot {item.plot_no}</a></li>
                            ))}
                        </ul>
                    </section>

                  <div className="results">
                    <div className="tse-scrollable">
                      <div className="tse-content">
                        <div className="section-title">
                          <h2>Search Results</h2>
                          {Plotid2 && <ApiResponse data={Plotid2} />}
                        </div>
                        <div className="results-content">
                      </div>
                      </div>
                    </div>
                  </div> 
            
            </div>
          </div>
        </div>
        <footer id="page-footer">
          <div className="footer-wrapper">
            <div className="block">
              <div className="container">
                <div className="vertical-aligned-elements">
                  <div className="element width-50">
                    <p data-toggle="modal" data-target="#myModal">
                      PLOTFINDER Plot Search is only currently only available for the whole of Botswana.
                    </p></div>
                </div>
                <div className="background-wrapper">
                  <div className="bg-transfer opacity-50">
                    <img src="assets/img/footer-bg.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="footer-navigation">
                <div className="container">
                  <div className="vertical-aligned-elements">2023 Designed by  Starlight Software Solutions (PTY) Ltd</div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
              </div>
               
        );
 
  };

export default Taxreturn;
